import { ForwardedRef, Ref, forwardRef } from "react";
import {
  ControllerFieldState,
  ControllerRenderProps,
  RefCallBack,
  UseFormRegisterReturn,
} from "react-hook-form";
import Select, {
  ActionMeta,
  GroupBase,
  Props,
  SingleValue,
} from "react-select";

export interface Option {
  value: string;
  label: string;
}

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  error?: string;
  // ref: ForwardedRef<GroupBase<Option>>;
};

export function InputSelect({ error, ...props }: SelectProps<Option>) {
  // @ts-ignore
  return (
    <div className="oqfc-input-container">
      <Select {...props} />
      {<span className="text-danger">{error}</span>}
    </div>
  );
}

// <Select
//   ref={ref}
//   placeholder="Selecione uma opção"
//   className="input-oqfc-select"
//   options={dataOption.benefits}
//   onChange={(v: SingleValue<Option>) => onChange(v)}
//   // error={errors.voucher?.reward?.value?.message}
// />
