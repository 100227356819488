import { useFormContext, useFieldArray } from "react-hook-form";
import { InputMask } from "..";

export function UsableTimes() {
  const prefix = "voucher.usableTimes";
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: prefix,
  });

  function handleAdd() {
    append({
      start: "",
      end: "",
      days: [],
      // monday: false,
      // tuesday: false,
      // wednesday: false,
      // thursday: false,
      // friday: false,
      // saturday: false,
      // sunday: false,
    });
  }

  function getErrorMessage(errors: any, index: number, fieldName: string) {
    if (errors?.voucher?.usableTimes) {
      if (errors?.voucher?.usableTimes[index]?.[fieldName]?.message)
        return errors?.voucher?.usableTimes[index]?.[fieldName]?.message;
    }
    return "";
  }

  return (
    <>
      {fields.map((item, index) => (
        <div
          className="col-md-5 col-xl-6 col-xxl-5 beneficios align-content-start d-flex flex-wrap"
          key={index}
        >
          <div className="row col-12 d-flex">
            <div className="col-md-5">
              <h3>In&iacute;cio</h3>
              <InputMask
                type="text"
                mask="hour"
                className="input-oqfc"
                placeholder="00:00"
                form={register(`${prefix}[${index}].start`)}
                error={getErrorMessage(errors, index, "start")}
              />
            </div>
            <div className="col-md-5">
              <h3>Fim</h3>
              <InputMask
                type="text"
                mask="hour"
                className="input-oqfc"
                placeholder="00:00"
                form={register(`${prefix}[${index}].end`)}
                error={getErrorMessage(errors, index, "end")}
              />
            </div>
          </div>

          <div className="col-12 d-flex flex-wrap justify-content-center justify-content-xl-start row">
            <div className="col-6">
              <input
                id={`${prefix}[${index}].days[monday]`}
                type="checkbox"
                value="monday"
                {...register(`${prefix}[${index}].days`)}
              />

              <label htmlFor={`${prefix}[${index}].days[monday]`}>
                &nbsp;&nbsp;Segunda
              </label>
              <br />

              <input
                id={`${prefix}[${index}].days[tuesday]`}
                type="checkbox"
                value="tuesday"
                {...register(`${prefix}[${index}].days`)}
              />

              <label htmlFor={`${prefix}[${index}].days[tuesday]`}>
                &nbsp;&nbsp;Ter&ccedil;a
              </label>
              <br />

              <input
                id={`${prefix}[${index}].days[wednesday]`}
                type="checkbox"
                value="wednesday"
                {...register(`${prefix}[${index}].days`)}
              />
              <label htmlFor={`${prefix}[${index}].days[wednesday]`}>
                &nbsp;&nbsp;Quarta
              </label>
              <br />

              <input
                id={`${prefix}[${index}].days[thursday]`}
                type="checkbox"
                value="thursday"
                {...register(`${prefix}[${index}].days`)}
              />
              <label htmlFor={`${prefix}[${index}].days[thursday]`}>
                &nbsp;&nbsp;Quinta
              </label>
              <br />
            </div>
            <div className="col-6">
              <input
                id={`${prefix}[${index}].days[friday]`}
                type="checkbox"
                value="friday"
                {...register(`${prefix}[${index}].days`)}
              />
              <label htmlFor={`${prefix}[${index}].days[friday]`}>
                &nbsp;&nbsp;Sexta
              </label>
              <br />
              <input
                id={`${prefix}[${index}].days[saturday]`}
                type="checkbox"
                value="saturday"
                {...register(`${prefix}[${index}].days`)}
              />
              <label htmlFor={`${prefix}[${index}].days[saturday]`}>
                &nbsp;&nbsp;S&aacute;bado
              </label>
              <br />
              <input
                id={`${prefix}[${index}].days[sunday]`}
                type="checkbox"
                value="sunday"
                {...register(`${prefix}[${index}].days`)}
              />
              <label htmlFor={`${prefix}[${index}].days[sunday]`}>
                &nbsp;&nbsp;Domingo
              </label>
              <br />
            </div>
            {getErrorMessage(errors, index, "days") && (
              <span className="text-danger">
                {getErrorMessage(errors, index, "days")}
              </span>
            )}

            {index > 0 && (
              <div className="col-12 d-flex flex-wrap justify-content-center">
                <button
                  type="button"
                  className="addBeneficioHorarios"
                  onClick={() => remove(index)}
                >
                  Excluir este hor&aacute;rio
                </button>
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="col-12 d-flex flex-wrap justify-content-center">
        <button
          type="button"
          className="addBeneficioHorarios"
          onClick={handleAdd}
        >
          ADICIONAR MAIS HOR&Aacute;RIOS
        </button>
      </div>
    </>
  );
}
