import { Category } from "./Category";
import { Establishment } from "./Establishment";
import { EstablishmentUnit } from "./EstablishmentUnit";
import { Plan } from "./Plan";
import { SelectOptions } from "./Utils";

export interface Voucher {
  id?: string;
  validationCode: string;
  plan?: Plan;
  planId: string | SelectOptions;
  name: string;
  establishment?: Establishment;
  establishmentId: string | SelectOptions;
  establishmentUnits: EstablishmentUnit[] | SelectOptions[];
  usableTimes: UsableTimes[];
  category?: Category;
  categoryId: string | SelectOptions;
  onPurchaseOf: string;
  reward: string | SelectOptions;
  establishmentRules: string;
  voucherType: string | SelectOptions;
  voucherStatus: string;
  images: { url: string }[] | File[];
  clientsThatUsed?: string;
  clientsThatFavorited?: string;
  categoryPriority?: number;
  featured?: boolean;
}

export interface UsableTimes {
  id?: string;
  start: string;
  end: string;
  days: string[];
}

export const VoucherStatus = {
  active: "Ativo",
  inactive: "Inativo",
} as const;

export const VoucherType = {
  normal: "Normal",
  advertising: "Publicidade",
} as const;
